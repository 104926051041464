import {PureAbility, MongoQuery, AbilityBuilder, createMongoAbility} from "@casl/ability";
import {equals} from "ramda";

import {AuthRight, CurrentMakerSummaryFragment} from "~/api/generated";
import {getEnumValues} from "~/utils/enums";

export type AbilityType = "read" | "manage";
export type SubjectType =
  | "order"
  | "order.confirm"
  | "products"
  | "customers"
  | "customers.export"
  | "pricingGroup"
  | "finance"
  | "report"
  | "commerce"
  | "listings"
  | "fulfillment"
  | "settings"
  | "all";

type Ability = [AbilityType, SubjectType];

export type AppAbility = PureAbility<Ability, MongoQuery>;

export const createAbilitiesFor = (maker: CurrentMakerSummaryFragment) => {
  const {can, build} = new AbilityBuilder<AppAbility>(createMongoAbility);

  if (
    equals(
      getEnumValues(AuthRight).sort((a, b) => a.localeCompare(b)),
      [...maker.rights].sort((a, b) => a.localeCompare(b))
    )
  ) {
    can("manage", "all");
  } else {
    if (maker.rights.includes(AuthRight.OrderRead)) {
      can("read", "order");
    }
    if (maker.rights.includes(AuthRight.OrderWrite)) {
      can("manage", "order");
    }
    if (maker.rights.includes(AuthRight.OrderConfirm)) {
      can("read", "order.confirm");
    }
    if (maker.rights.includes(AuthRight.ProductRead)) {
      can("read", "products");
    }
    if (maker.rights.includes(AuthRight.ProductWrite)) {
      can("manage", "products");
    }
    if (maker.rights.includes(AuthRight.CustomerRead)) {
      can("read", "customers");
    }
    if (maker.rights.includes(AuthRight.CustomerWrite)) {
      can("manage", "customers");
    }
    if (maker.rights.includes(AuthRight.CustomerExport)) {
      can("read", "customers.export");
    }
    if (maker.rights.includes(AuthRight.PricingGroupRead)) {
      can("read", "pricingGroup");
    }
    if (maker.rights.includes(AuthRight.PricingGroupWrite)) {
      can("manage", "pricingGroup");
    }
    if (maker.rights.includes(AuthRight.FinanceRead)) {
      can("read", "finance");
    }
    if (maker.rights.includes(AuthRight.ReportRead)) {
      can("read", "report");
    }
    if (maker.rights.includes(AuthRight.CommerceRead)) {
      can("read", "commerce");
    }
    if (maker.rights.includes(AuthRight.CommerceWrite)) {
      can("manage", "commerce");
    }
    if (maker.rights.includes(AuthRight.ListingsRead)) {
      can("read", "listings");
    }
    if (maker.rights.includes(AuthRight.FulfillmentWrite)) {
      can("manage", "fulfillment");
    }
    if (maker.rights.includes(AuthRight.FulfillmentRead)) {
      can("read", "fulfillment");
    }
    if (maker.rights.includes(AuthRight.SettingsWrite)) {
      can("manage", "settings");
    }
    if (maker.rights.includes(AuthRight.SettingsRead)) {
      can("read", "settings");
    }
  }

  return build();
};
