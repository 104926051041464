import React from "react";

const lazyRetry = <T extends React.ComponentType<any>>(
  componentImport: () => Promise<{default: T}>,
  name?: string
): Promise<{default: T}> => {
  const itemKey = name === undefined ? "retry-lazy-refreshed" : `retry-${name}-refreshed`;
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(itemKey) || "false");

    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(itemKey, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(itemKey, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default lazyRetry;
