import {useHistory} from "react-router-dom";
import {ZendeskAPI} from "react-zendesk";

import {LOGIN_PATH} from "~/routes/constants";
import {useAppDispatch} from "~/store/config/hooks";
import {client} from "~/api/baseApi";
import {api} from "~/api/generated";
import {resetAll} from "~/store/reducers/global";
import {useLocalStorage} from "~/hooks";

export const useAuth = (): [boolean, (v: any, opt?: object) => void, (opt?: object) => void] => {
  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage("TOKEN");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isAuthorized = !!authToken;

  const setAuth = (value: any) => {
    client.setHeader("authorization", value ? `Bearer ${value}` : "");
    setAuthToken(value);
  };

  const resetAuth = () => {
    removeAuthToken();
    dispatch(api.util.resetApiState());
    dispatch(resetAll());
    ZendeskAPI("webWidget", "logout");
    history.push({
      pathname: LOGIN_PATH,
      state: {from: history.location.pathname},
    });
  };

  return [isAuthorized, setAuth, resetAuth];
};
