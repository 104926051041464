import {Link} from "react-router-dom";
import Box from "@mui/material/Box";

import {BmPrimaryButton} from "~/components/buttons";
import {BmNewLogo} from "~/components/icons/otherIcons";
import {HOME_PATH} from "~/routes/constants";
import {designColors} from "~/ui";

import {styles} from "./Header.styles";

interface HeaderProps {
  buttonName?: string;
  onButtonClick?: VoidFunction;
}
export const Header: React.FC<HeaderProps> = ({onButtonClick, buttonName}) => {
  return (
    <Box className={styles.root}>
      <Link to={HOME_PATH}>
        <BmNewLogo id="desktop-header-logo-icon" color={designColors.white} />
      </Link>
      {buttonName && <BmPrimaryButton onClick={onButtonClick}>{buttonName}</BmPrimaryButton>}
    </Box>
  );
};
