import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {Link} from "react-router-dom";

import {HOME_PATH} from "~/routes/constants";
import {BmNewLogo} from "~/components/icons/otherIcons";
import {designColors} from "~/ui";

import {styles} from "./Sidebar.styles";
import {SidebarList} from "./SIdebarList/SidebarList";

interface SidebarProps {
  mobileMenuOpen: boolean;
  mobileMenuToggle: VoidFunction;
}

export const Sidebar: React.FC<SidebarProps> = ({mobileMenuOpen, mobileMenuToggle}) => {
  const theme = useTheme();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  return isLowerThanLg ? (
    <SwipeableDrawer
      variant="temporary"
      open={mobileMenuOpen}
      onOpen={mobileMenuToggle}
      onClose={mobileMenuToggle}
      className={styles.sidebar}
      disableSwipeToOpen={false}
    >
      <Link to={HOME_PATH} className={styles.logoLink}>
        <BmNewLogo id="mobile-sidebar-logo-icon" color={designColors.white} />
      </Link>
      <SidebarList />
    </SwipeableDrawer>
  ) : (
    <Drawer variant="permanent" open className={styles.sidebar}>
      <Link to={HOME_PATH} className={styles.logoLink}>
        <BmNewLogo id="desktop-sidebar-logo-icon" color={designColors.white} />
      </Link>
      <SidebarList />
    </Drawer>
  );
};
