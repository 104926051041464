import {Button as MuiButton} from "@mui/material";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import {cx} from "@emotion/css";
import {useMemo} from "react";

import {IButton} from "../IButton";
import {styles} from "./SecondaryButton.styles";
import {commonStyles} from "../_common/styles";

export interface BmSecondaryButtonProps
  extends Pick<IButton, "className" | "sx" | "type" | "disabled" | "onClick" | "children"> {
  loading?: boolean;
  loadingIndicator?: string;
}

export const BmSecondaryButton: React.FC<BmSecondaryButtonProps> = ({
  className,
  loading,
  loadingIndicator,
  ...restProps
}) => {
  const commonProps = useMemo(
    () => ({
      className: cx(commonStyles.button, styles.secondaryButton, className),
      ...restProps,
    }),
    [className, restProps]
  );

  return loading === undefined ? (
    <MuiButton variant="text" color="primary" {...commonProps} />
  ) : (
    <MuiLoadingButton
      variant="text"
      color="primary"
      loading={loading}
      loadingIndicator={loadingIndicator}
      {...commonProps}
    />
  );
};
