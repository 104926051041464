import {createSelector} from "@reduxjs/toolkit";

import {Addon} from "~/api/generated";
import {RootState} from "~/store/config/configureStore";

export const selectUser = createSelector(
  (state: RootState) => state,
  (state) => state.user
);

export const selectMaker = createSelector(selectUser, (user) => user.maker);

/**
 * you should use it only inside children components
 * of the pages that are protected by useProtectedMaker hook
 */
export const strongSelectMaker = createSelector(selectUser, (user) => user.maker!);

export const strongSalesUser = createSelector(selectUser, (user) => user.maker!.addons.includes(Addon.SalesUser));
