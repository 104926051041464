import React, {useEffect} from "react";

import {useAuth, useCurrentUser} from "~/hooks";
import {useAppSelector} from "~/store/config/hooks";
import {selectMaker} from "~/store/selectors/userSelectors";

export const withEssentialPrivateData =
  <P,>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const maker = useAppSelector(selectMaker);
    const [, isCurrentUserError] = useCurrentUser();
    const [isAuthorized, , resetAuth] = useAuth();

    useEffect(() => {
      if (isAuthorized) return;
      resetAuth();
    }, [isAuthorized, resetAuth]);

    useEffect(() => {
      if (!isCurrentUserError) return;
      resetAuth();
    }, [isCurrentUserError, resetAuth]);

    if (!maker) return null;

    return <Component {...props} />;
  };
