import {cx} from "@emotion/css";
import {Button as MuiButton, ButtonProps} from "@mui/material";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import {useMemo} from "react";

import {noop} from "~/utils/noop";

import {IButton} from "../IButton";
import {styles} from "./PrimaryButton.styles";
import {commonStyles} from "../_common/styles";

export interface BmPrimaryButtonProps
  extends Pick<IButton, "id" | "className" | "sx" | "type" | "form" | "disabled" | "onClick" | "children"> {
  color?: ButtonProps["color"];
  loading?: boolean;
  loadingIndicator?: string;
}

export const BmPrimaryButton: React.FC<BmPrimaryButtonProps> = ({
  className,
  color = "primary",
  loading,
  loadingIndicator,
  onClick,
  ...restProps
}) => {
  const commonProps = useMemo(
    () => ({
      className: cx(commonStyles.button, styles.primaryButton, className),
      color,
      ...restProps,
    }),
    [className, color, restProps]
  );

  return loading === undefined ? (
    <MuiButton variant="contained" onClick={onClick} {...commonProps} />
  ) : (
    <MuiLoadingButton
      variant="contained"
      loading={loading}
      loadingIndicator={loadingIndicator}
      onClick={loading ? noop : onClick}
      {...commonProps}
    />
  );
};
