import {Box} from "@mui/material";

import {BmDefaultPageLayout} from "~/layouts/pageLayouts";
import {withCompanyInfo} from "~/modules/company";
import {withAbilities} from "~/modules/roles";
import {withEssentialPrivateData} from "~/routes/hocs/withEssentialPrivateData";

import {NotFoundContent} from "./container/NotFoundContent/NotFoundContent";

const NotFoundPrivate: React.FC = () => {
  return (
    <BmDefaultPageLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: {xs: "50px", sm: "100px", md: "50px"},
        }}
      >
        <NotFoundContent />
      </Box>
    </BmDefaultPageLayout>
  );
};

export default withEssentialPrivateData(withAbilities(withCompanyInfo(NotFoundPrivate)));
