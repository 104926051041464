import {Suspense, useMemo} from "react";
import {Route, Redirect, useLocation} from "react-router-dom";
import {FlagsProvider} from "flagged";
import {Helmet, HelmetProvider} from "react-helmet-async";

import {useAuth, useCurrentUser} from "~/hooks";
import {AnyObject} from "~/model/helperTypes/generic";
import {useAppSelector} from "~/store/config/hooks";
import {selectMaker} from "~/store/selectors/userSelectors";
import {PublicModals} from "~/containers/modals/PublicModals";
import {AuthRight} from "~/api/generated";

import {HOME_PATH} from "./constants";

interface PublicRouteProps {
  exact?: boolean;
  path: string;
  SuspenseFallbackComponent: React.ComponentType<any>;
  PageComponent: React.ComponentType<any>;
  componentProps: AnyObject;
  restrictedFromAuthorized?: boolean;
  zendeskRelatedLabels?: string[];
  accessRight?: AuthRight;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  SuspenseFallbackComponent,
  PageComponent,
  componentProps,
  restrictedFromAuthorized,
  zendeskRelatedLabels,
  ...restRouteProps
}) => {
  useCurrentUser();

  const maker = useAppSelector(selectMaker);
  const [isAuthorized] = useAuth();
  const {pathname} = useLocation();

  const features = useMemo(() => {
    let nextFeatures: AnyObject = {
      // possible values: local, staging, production
      [process.env.BEMAKERS_ENV!]: true,
    };

    maker?.features?.forEach((feature) => {
      nextFeatures = {
        ...nextFeatures,
        [feature]: true,
      };
    });

    return nextFeatures;
  }, [maker?.features]);

  return (
    <Route {...restRouteProps}>
      {isAuthorized && restrictedFromAuthorized ? (
        <Redirect
          to={{
            pathname: HOME_PATH,
            state: {from: pathname},
          }}
        />
      ) : (
        <FlagsProvider features={features}>
          <HelmetProvider>
            <Helmet>
              {zendeskRelatedLabels && <meta name="keywords" content={zendeskRelatedLabels.join(", ")} />}
            </Helmet>
          </HelmetProvider>
          <Suspense fallback={<SuspenseFallbackComponent />}>
            <PageComponent {...componentProps} />
          </Suspense>
          <PublicModals />
        </FlagsProvider>
      )}
    </Route>
  );
};

export default PublicRoute;
