import {lazy} from "react";

import lazyRetry from "~/utils/lazyLoading";
import {GuestRouteFallback, PrivateRouteFallback, SharedRouteFallback} from "~/components/suspenseFallbacks";

import {Route} from "./types";

export const createGuestRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: false,
    exact: true,
    strict: true,
    restrictedFromAuthorized: true,
    SuspenseFallbackComponent: GuestRouteFallback,
    ...options,
  };
};

export const createSharedAccessRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: false,
    exact: true,
    strict: true,
    restrictedFromAuthorized: false,
    SuspenseFallbackComponent: SharedRouteFallback,
    ...options,
  };
};

export const createPrivateRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: true,
    exact: true,
    strict: true,
    SuspenseFallbackComponent: PrivateRouteFallback,
    ...options,
  };
};

// Template for lazy import with lazyRetry
export const createLazyPageComponent = (path: string) => {
  return lazy(() =>
    lazyRetry(
      () => import(/* webpackChunkName: "[request]" */ `~/pages/${path.replace("~/pages/", "")}`),
      path.split("/").pop()
    )
  );
};
