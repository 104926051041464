import {configureStore} from "@reduxjs/toolkit";

import {api} from "~/api/baseApi";
import {authApi} from "~/api/authApi";
import {stockMovementApi} from "~/api/stockMovementApi";
import {readyForPickupApi} from "~/api/readyForPickupApi";
import {manifestApi} from "~/api/manifestApi";

import localBeverageReducer from "../reducers/localBeverage";
import localBundleProductReducer from "../reducers/localBundleProduct";
import localExtraProductReducer from "../reducers/localExtraProduct";
import localWebshopProductReducer from "../reducers/localWebshopProduct";
import localOrderReducer from "../reducers/localOrder";
import userReducer from "../reducers/user";
import uiReducer from "../reducers/ui";
import localCustomerReducer from "../reducers/localCustomer";
import {unauthenticatedMiddleware} from "../middleware/unauthenticatedMiddleware";

export const store = configureStore({
  reducer: {
    localBeverage: localBeverageReducer,
    localBundleProduct: localBundleProductReducer,
    localExtraProduct: localExtraProductReducer,
    localWebshopProduct: localWebshopProductReducer,
    localOrder: localOrderReducer,
    localCustomer: localCustomerReducer,
    user: userReducer,
    ui: uiReducer,
    [authApi.reducerPath]: authApi.reducer,
    [stockMovementApi.reducerPath]: stockMovementApi.reducer,
    [readyForPickupApi.reducerPath]: readyForPickupApi.reducer,
    [manifestApi.reducerPath]: manifestApi.reducer,
    [api.reducerPath]: api.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "localBeverage/setStep",
          "localBundleProduct/setStep",
          "localExtraProduct/setStep",
          "localOrder/setOrder",
          "localOrder/updateOrder",
        ],
        ignoredPaths: [
          "localBeverage.localBeverage.images",
          "localBeverage.localBeverage.ogImageFile",
          "localBundleProduct.localBundleProduct.images",
          "localBundleProduct.localBundleProduct.ogImageFile",
          "localExtraProduct.localExtraProduct.images",
          "localExtraProduct.localExtraProduct.ogImageFile",
          "localOrder.localOrder.customer_search.image",
          "localOrder.localOrder.packagingDate",
          "localOrder.localOrder.pickupDate",
          "localOrder.localOrder.deliveryDate",
          "localOrder.localOrder.waybillFile",
        ],
      },
    }).concat(
      unauthenticatedMiddleware,
      authApi.middleware,
      manifestApi.middleware,
      stockMovementApi.middleware,
      readyForPickupApi.middleware,
      api.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
