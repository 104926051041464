import {useTranslation} from "react-i18next";
import {useCallback, useEffect} from "react";
import Box from "@mui/material/Box";

import {useOpenClose, useLocalStorage} from "~/hooks";
import {BmPrimaryButton} from "~/components/buttons";
import {useAppSelector} from "~/store/config/hooks";
import {strongSelectMaker} from "~/store/selectors/userSelectors";
import {BEMAKERS_HELP_CENTER_LINK} from "~/constants/constants";
import {BmBaseModal} from "~/components/modals";

export const OnboardingModal: React.FC = () => {
  const {t} = useTranslation();
  const maker = useAppSelector(strongSelectMaker);
  const [isOnBoarding, , resetOnBoarding] = useLocalStorage("onboarding");
  const [isOnBoardingModalOpen, openOnBoardingModal, closeOnBoardingModal] = useOpenClose(false);

  const resetOnBoardingModal = useCallback(() => {
    resetOnBoarding();
    closeOnBoardingModal();
  }, [resetOnBoarding, closeOnBoardingModal]);

  useEffect(() => {
    if (!isOnBoarding) return;
    openOnBoardingModal();
  }, [isOnBoarding, openOnBoardingModal]);

  return (
    <BmBaseModal
      title={t("invite.modal.title")}
      open={isOnBoardingModalOpen}
      onClose={resetOnBoardingModal}
      maxWidth={"626px"}
      closeButton
    >
      <Box
        mb={4}
        dangerouslySetInnerHTML={{
          __html: t(
            !!maker?.onboarding?.chargeMethods?.length ? "invite.modal.description_2" : "invite.modal.description_1",
            {
              makerName: maker?.name,
              link: BEMAKERS_HELP_CENTER_LINK,
            }
          ),
        }}
      />
      <BmPrimaryButton onClick={resetOnBoardingModal}>{t("invite.modal.button")}</BmPrimaryButton>
    </BmBaseModal>
  );
};
