/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import {useCallback, useEffect, useState} from "react";

import {RESTErrorType, RESTFieldErrorType} from "~/model/helperTypes/Errors";
import {isRegularRESTError} from "~/utils/errors";
import {errorToast} from "~/utils/toasts/errorToast";

type ErrorHandlerType = (error: RESTErrorType) => void;

export const useRESTErrorCatch = (
  error?: RESTErrorType
): [RESTFieldErrorType[] | null, {errorHandler: ErrorHandlerType; cleanErrors: () => void}] => {
  const [errors, setErrors] = useState<RESTFieldErrorType[] | null>(null);

  const errorHandler = useCallback<ErrorHandlerType>((error) => {
    if (isRegularRESTError(error)) {
      const currentError: RESTErrorType = error;
      setErrors(currentError.data.errors.map(({message}) => ({message})));
      console.error("Backend rest error:", currentError);
    } else {
      setErrors(null);
      errorToast(`Error: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
      console.error("Backend irregular error:", error);
    }
  }, []);

  const cleanErrors = useCallback(() => {
    setErrors(null);
  }, []);

  useEffect(() => {
    if (!error) return;
    errorHandler(error);
  }, [error, errorHandler]);

  return [errors, {errorHandler, cleanErrors}];
};
