/* eslint-disable no-console */
const backup = console.error;
const supressedWarnings = ["`@import` rules must be before all other types of rules in a stylesheet"];

console.error = function filterWarnings(msg) {
  if (typeof msg === "string") {
    if (supressedWarnings.some((entry) => msg.includes(entry))) {
      return;
    }
  }
  backup.apply(console, arguments);
};
