import {useEffect} from "react";
import {useHistory} from "react-router-dom";

import {CurrentMakerSummaryFragment} from "~/api/generated";
import {HOME_PATH} from "~/routes/constants";
import {selectMaker} from "~/store/selectors/userSelectors";
import {useAppSelector} from "~/store/config/hooks";

export const useProtectedMaker = (): [CurrentMakerSummaryFragment | undefined] => {
  const history = useHistory();

  const maker = useAppSelector(selectMaker);

  useEffect(() => {
    // if maker doesn't exist, BE returns null
    maker === null && history.push(HOME_PATH);
  }, [maker, history]);

  return [maker as CurrentMakerSummaryFragment | undefined];
};
