import React from "react";

import {routes} from "./routes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export const getRoutes = (): React.ReactNode[] =>
  routes.map(
    ({
      isPrivate,
      restrictedFromAuthorized,
      exact,
      path,
      zendeskRelatedLabels,
      PageComponent,
      SuspenseFallbackComponent,
      accessRight,
      ...componentProps
    }) => {
      return isPrivate ? (
        <PrivateRoute
          key="privateRoute"
          exact={exact}
          path={path}
          zendeskRelatedLabels={zendeskRelatedLabels}
          PageComponent={PageComponent}
          SuspenseFallbackComponent={SuspenseFallbackComponent}
          componentProps={componentProps}
          accessRight={accessRight}
        />
      ) : (
        <PublicRoute
          key={PageComponent.displayName || path}
          exact={exact}
          path={path}
          zendeskRelatedLabels={zendeskRelatedLabels}
          PageComponent={PageComponent}
          SuspenseFallbackComponent={SuspenseFallbackComponent}
          componentProps={componentProps}
          restrictedFromAuthorized={restrictedFromAuthorized}
          accessRight={accessRight}
        />
      );
    }
  );
