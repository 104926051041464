import {useEffect} from "react";
import {useHistory} from "react-router-dom";

import {TERMS_TO_UPDATE_PATH} from "~/routes/constants";
import {selectUser} from "~/store/selectors/userSelectors";
import {useAppSelector} from "~/store/config/hooks";

export const useUpdatedTerms = () => {
  const history = useHistory();
  const {maker, impersonation} = useAppSelector(selectUser);

  useEffect(() => {
    if (impersonation) return;
    if (!maker?.onboarding?.termsToUpdate.length) return;
    history.push(TERMS_TO_UPDATE_PATH);
  }, [history, impersonation, maker]);
};
