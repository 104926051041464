import {isRejectedWithValue, Middleware} from "@reduxjs/toolkit";

import {KnownErrorCodesEnum} from "~/model/helperTypes/Errors";
import {LOGIN_PATH} from "~/routes/constants";

export const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const errors = action?.meta?.baseQueryMeta?.response?.errors || [];
    if (errors[0]?.extensions?.code === KnownErrorCodesEnum.Unauthenticated) {
      window.localStorage.removeItem("TOKEN");
      window.location.href = LOGIN_PATH;
      return;
    }
  }
  return next(action);
};
