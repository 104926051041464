import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgProducts} from "~/assets/svg/products.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmProductsIcon: React.FC<IIconBase> = ({
  id,
  size = "sm",
  color = designColors.black,
  strokeWidth = "initial",
  className,
}) => {
  return (
    <SvgProducts
      id={id}
      className={cx(
        simpleIconStyles(size),
        commonStyles.color(color),
        commonStyles.strokeWidth(strokeWidth),
        className
      )}
    />
  );
};
