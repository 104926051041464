import {Suspense, useMemo} from "react";
import {Route} from "react-router-dom";
import {FlagsProvider} from "flagged";
import {Helmet, HelmetProvider} from "react-helmet-async";

import {useZendeskPrefill, useZendeskSuggestedPosts, useInvitation, useUpdatedTerms} from "~/hooks";
import {AnyObject} from "~/model/helperTypes/generic";
import {useAppSelector} from "~/store/config/hooks";
import {strongSelectMaker} from "~/store/selectors/userSelectors";
import {PrivateModals} from "~/containers/modals/PrivateModals";
import {AuthRight} from "~/api/generated";
import {withAbilities} from "~/modules/roles";
import NotFound from "~/pages/notFound/NotFound";
import {withCompanyInfo} from "~/modules/company";

import {withEssentialPrivateData} from "./hocs/withEssentialPrivateData";

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  SuspenseFallbackComponent: React.ComponentType<any>;
  PageComponent: React.ComponentType<any>;
  componentProps: AnyObject;
  zendeskRelatedLabels?: string[];
  accessRight?: AuthRight;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  SuspenseFallbackComponent,
  PageComponent,
  componentProps,
  zendeskRelatedLabels,
  accessRight,
  ...restRouteProps
}) => {
  useZendeskPrefill();
  useZendeskSuggestedPosts();
  useInvitation();
  useUpdatedTerms();

  const maker = useAppSelector(strongSelectMaker);

  const features = useMemo(() => {
    let nextFeatures: AnyObject = {
      // possible values: local, staging, production
      [process.env.BEMAKERS_ENV!]: true,
    };

    maker.features.forEach((feature) => {
      nextFeatures = {
        ...nextFeatures,
        [feature]: true,
      };
    });

    return nextFeatures;
  }, [maker.features]);

  if (accessRight && !maker.rights.includes(accessRight)) return <NotFound />;

  return (
    <Route {...restRouteProps}>
      <FlagsProvider features={features}>
        <HelmetProvider>
          <Helmet>{zendeskRelatedLabels && <meta name="keywords" content={zendeskRelatedLabels.join(", ")} />}</Helmet>
        </HelmetProvider>

        <Suspense fallback={<SuspenseFallbackComponent />}>
          <PageComponent {...componentProps} />
        </Suspense>
        <PrivateModals />
      </FlagsProvider>
    </Route>
  );
};

export default withEssentialPrivateData(withAbilities(withCompanyInfo(PrivateRoute)));
