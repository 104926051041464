import {cx} from "@emotion/css";
import {SxProps, Theme} from "@mui/material";
import Box from "@mui/material/Box";

import {designColors, designConstants} from "~/ui";

export interface BmRegularPaperProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const BmRegularPaper: React.FC<BmRegularPaperProps> = ({className, sx, children}) => {
  return (
    <Box
      className={cx(className)}
      sx={{
        p: 4,
        borderRadius: designConstants.borderRadius,
        backgroundColor: designColors.white,
        boxShadow: designConstants.boxShadow,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
