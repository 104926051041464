import {useCallback, useEffect, useMemo} from "react";
import {useHistory} from "react-router-dom";

import {MakerFeatures} from "~/features";
import {INVITE_SETUP_PATH} from "~/routes/constants";
import {selectUser} from "~/store/selectors/userSelectors";
import {useAppSelector} from "~/store/config/hooks";

export const useInvitation = () => {
  const history = useHistory();
  const {maker, impersonation} = useAppSelector(selectUser);

  const chargeMethodToAccept = useMemo(() => {
    if (!maker?.features.includes(MakerFeatures.ChargeMethod)) return;
    if (!maker.onboarding?.chargeMethods) return;
    return maker.onboarding.chargeMethods;
  }, [maker]);

  const goToInvitePage = useCallback(() => {
    history.push(INVITE_SETUP_PATH);
  }, [history]);

  useEffect(() => {
    if (impersonation) return;
    if (!maker?.onboarding?.termsToAccept.length) return;
    goToInvitePage();
  }, [maker, goToInvitePage, impersonation]);

  useEffect(() => {
    if (impersonation) return;
    if (chargeMethodToAccept === undefined) return;
    if (chargeMethodToAccept.length > 0) return;
    goToInvitePage();
  }, [chargeMethodToAccept, goToInvitePage, impersonation]);
};
