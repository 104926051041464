import {BmTableBody} from "~/components/table/TableBody/TableBody";

import {BmTable} from "../../table/Table/Table";
import {BmTableCell} from "../../table/TableCell/TableCell";
import {BmTableRow} from "../../table/TableRow/TableRow";
import {BmSkeleton} from "../Skeleton/Skeleton";

export interface BmTableSkeletonProps {
  rows?: number;
  cells?: number;
  className?: string;
  isBorderVisible?: boolean;
}

export const BmTableSkeleton: React.FC<BmTableSkeletonProps> = ({
  rows = 1,
  cells = 8,
  className,
  isBorderVisible = true,
}) => {
  return (
    <BmTable className={className} sx={isBorderVisible ? {} : {"& tr": {border: "none !important"}}}>
      <BmTableBody>
        {[...Array(rows)].map((_, i) => (
          <BmTableRow key={i} hover={false}>
            {[...Array(cells)].map((_, i) => (
              <BmTableCell key={i}>
                <BmSkeleton height={18} />
              </BmTableCell>
            ))}
          </BmTableRow>
        ))}
      </BmTableBody>
    </BmTable>
  );
};
