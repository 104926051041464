import {useAbility} from "@casl/react";
import {useEffect, useMemo} from "react";

import {CompanySummaryFragment, useLazyGetCompanyInfoQuery} from "~/api/generated";
import {AbilityContext} from "~/modules/roles";
import {useAppSelector} from "~/store/config/hooks";
import {strongSelectMaker} from "~/store/selectors/userSelectors";
import {enhanceCompanyData} from "~/utils/company";

export const useCompanyInfoQuery = (): {data: CompanySummaryFragment | null; isLoading: boolean} => {
  const maker = useAppSelector(strongSelectMaker);
  const ability = useAbility(AbilityContext);
  const [getCompanyInfo, {data: companyInfoData, isLoading: isCompanyInfoDataLoading}] = useLazyGetCompanyInfoQuery();
  const companyData = useMemo(
    () => (companyInfoData?.maker ? enhanceCompanyData(companyInfoData.maker) : null),
    [companyInfoData?.maker]
  );

  useEffect(() => {
    ability.can("read", "settings") && getCompanyInfo({makerId: maker.id});
  }, [ability, getCompanyInfo, maker.id]);

  return {data: companyData, isLoading: isCompanyInfoDataLoading};
};
